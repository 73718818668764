import { LocaleContextPlugin } from '@objectiv/plugin-locale-context';
import { RootLocationContextFromURLPlugin } from '@objectiv/plugin-root-location-context-from-url';
import { IdentityContextAttributes, IdentityContextPlugin } from '@objectiv/plugin-identity-context';
import { makeId, TrackerConfig } from '@objectiv/tracker-core';
import Cookies from 'universal-cookie';
import { v4 as uuid } from 'uuid';
import { gatsbyAppEnvironment, isProd } from '~utils/env';
import { useProspectId } from '../ProspectProvider';
import { InsifyObjectivProvider, getProspectIdentityContext, getDistributionIdentityContext } from '@insify/objectiv';
import { COUNTRY } from '~globals';

// This still ends up in the main bundle despite the if check. Uncomment if it's needed for development.
// if (isLocalDev && typeof window !== 'undefined') require('@objectiv/developer-tools');

type ObjectivTrackerProviderProps = {
  children: React.ReactNode;
  isConsentGiven: boolean;
};

const FLAG_SET = 'flagSet';
const FLAG_SET_SOURCE = 'flagSetSource';
const DEVICE_ID = 'device_id';

const applicationId = `${gatsbyAppEnvironment}-landing-pages-${COUNTRY}`;

const cookies = new Cookies();

const getDeviceIdentityContext = (): IdentityContextAttributes[] => {
  let deviceId: string | undefined = cookies.get(DEVICE_ID);

  if (!deviceId) {
    deviceId = uuid();

    cookies.set(DEVICE_ID, deviceId, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
      domain: `.insify.${COUNTRY}`,
      sameSite: 'lax',
    });
  }

  return [{ id: 'trackingUserId', value: deviceId }];
};

const getFlagSetIdentityContext = (): IdentityContextAttributes[] => {
  const flagSet: string | undefined = cookies.get(FLAG_SET);

  return flagSet
    ? [
        { id: FLAG_SET_SOURCE, value: 'landingPages' },
        { id: FLAG_SET, value: flagSet },
      ]
    : [];
};

export const ObjectivTrackerProvider: React.FC<ObjectivTrackerProviderProps> = ({
  children,
  isConsentGiven,
}): JSX.Element => {
  const { prospectId } = useProspectId();

  const identityContext: IdentityContextAttributes[] = [
    ...getProspectIdentityContext(prospectId),
    ...getDistributionIdentityContext(),
    ...getDeviceIdentityContext(),
    ...getFlagSetIdentityContext(),
  ];

  const plugins: TrackerConfig['plugins'] = [
    new LocaleContextPlugin({
      languageFactoryFunction: () => COUNTRY,
    }),
    new RootLocationContextFromURLPlugin({
      idFactoryFunction: () => makeId(location.pathname.split('/').join('-')) ?? 'home',
    }),
    new IdentityContextPlugin(identityContext),
  ];

  return (
    <InsifyObjectivProvider
      applicationId={applicationId}
      isProd={isProd}
      isConsentGiven={isConsentGiven}
      plugins={plugins}
    >
      {children}
    </InsifyObjectivProvider>
  );
};
